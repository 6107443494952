import dateFormat from 'dateformat';
import { addDaysToCurrentDate, getDayOfWeek } from 'site-modules/shared/utils/date-utils';

export function getEspTrustDateUnformatted() {
  const dayOfWeek = getDayOfWeek();

  const weekShift = dayOfWeek < 4 ? -14 : -7;
  const dayShift = 1 - dayOfWeek;

  return addDaysToCurrentDate(weekShift + dayShift);
}

export function getEspTrustDate() {
  return dateFormat(getEspTrustDateUnformatted(), 'mmmm dS');
}

export function getEspTrustDateText() {
  return `Based on nearby sales up through ${getEspTrustDate()}`;
}
